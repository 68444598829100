import React from 'react';
import {FAQNuevo} from 'src/templates';
import {Box, Container, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/styles';
import { Link } from "gatsby"
import iconoAnticipos from "src/assets/svg/ayuda/icono-anticipos.svg"
// Imports para los contenidos
import { getContent } from 'src/queries';
import  HeaderHeight from "src/components/common/HeaderHeight"

export default function Anticipos() {
 const theme = useTheme();
 const sm = useMediaQuery(theme.breakpoints.down('md'));

 const faqInversiones = getContent("faq-anticipos", process.env.GATSBY_REACT_APP_REGION)

 return (
  <Box sx={{ background: `${theme.palette.primary.main}20` }}>
   {process.env.GATSBY_REACT_APP_REGION === "es-PE" &&
     <HeaderHeight />
   }
   <Container>
    <Box sx={{ maxWidth: "1100px", margin: "0 auto"}}>
     <Box sx={{ padding: "72px 0"}}>
      <Box sx={{ marginBottom: "32px"}}>
       <Typography variant="h3" color="primary.dark" fontWeight={500}>Preguntas frecuentes</Typography>
      </Box>
      <Box sx={{ mt: 8, mb: 6}}>
       <Link to="/ayuda/" style={{ textDecoration: "none" }}>
        <Typography display="inline" color="secondary.main" sx={{fontWeight: 700}}>Preguntas frecuentes</Typography>
       </Link>
       <Typography display="inline" sx={{ ml: 2, mr: 2}}>{`>`}</Typography>
       <Typography display="inline">Anticipos</Typography>
      </Box>

      <FAQNuevo 
       titulo="Anticipos"
       descripcion="Preguntas frecuentes sobre nuestra solución de financiamiento para empresas."
       icono={iconoAnticipos}
       preguntas={faqInversiones}
      />

     </Box>
    </Box>
   </Container>
  </Box>
 );
};
